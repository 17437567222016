import styled from 'styled-components';

export default styled.div`
  margin: 1rem 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  padding: 0.25rem 0;
  font-size: 1rem;
`;

export const Price = styled.div``;
