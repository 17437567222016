import React from 'react';
import styled, { css } from 'styled-components';
import { ButtonBordered } from '../ui/Button';

import { Button as SvgButton, Icon } from '../ui';

import Text from '../ui/Text/Text';
import TitleComponent from '../ui/Title';
import { Row, Col } from '../ui/Grid';
import { above } from '../../styles/libs';
import binSvg from '../../static/images/icons/bin.svg';
import checkSvg from '../../static/images/icons/Check.svg';

export const MiniBasketItem = styled.div`
  flex-shrink: 0;
  position: relative;
  align-self: flex-start;
  font-size: 0.875rem;
  padding: 0 1.75rem;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  width: 100%;

  &:not(:first-child) {
    ${({ theme, subItem, reviewItem }) => !subItem && !reviewItem && css`
      border-top: 1px solid ${theme.COLOR_GRAY_LIGHT};
    `}
  }
  ${({ borderColor }) => borderColor && (
    css`&::before {
      position: absolute;
      content: "";
      background-color: ${borderColor};
      width: 4px;
      height: 100%;
      left: 0;
      top: 0;
    }
  `)}
  ${({ subItem }) => subItem && css`
    width: 100%;
    max-width: calc(100% - 2rem);
    margin: 1rem;
    background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTER};
    border-radius: 0.2rem;
    margin-top: 0;
  `}
  ${({ reviewItem }) => reviewItem && css`
    margin-bottom: 1rem;
    padding: 1.75rem;
    border-radius: 0.2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  `}
  ${({ cancellingItem, theme }) => cancellingItem && css`
    border: 3px solid ${theme.COLOR_DANGER};
  `}
`;

export const BasketItemRow = styled(Row)`
  /* Negate header margin */
  margin-top: -0.75rem;
`;

export const BasketItemCol = styled(Col)`
  flex: 0 0 100%;
  margin-bottom: 1rem;
  ${({ bigBasket, theme }) => bigBasket && above(theme.MOBILE_LARGE)`
    flex: 0 0 50%;
    margin: 0;
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 0.75rem 0;
  justify-content: space-between;
`;

export const Campsite = styled.div`
  flex: 0 0 100%;
  order: 3;
  ${({ full }) => full && css`
    flex: 1;
    order: 0;
  `}
`;

export const Image = styled.div`
  ${({ src }) => src && css`
    background-image: url(${src});
  `}
  background-size: 100%;
  background-position: center;
  border-radius: 5px;
  width: 4.75rem;
  height: 3.375rem;
  margin-right: 1rem;
  ${({ svg, theme }) => svg && css`
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Cdefs%3E%3Cstyle%3E.event-icon-1%7Bfill:%23237274;%7D%3C/style%3E%3C/defs%3E%3Cpath class='event-icon-1' d='M161.44,66.65C88.68,57.11,33.07,199.14,33.07,199.14L162.39,230s102.32-56.87,102.32-57.19C226.26,70.46,161.44,66.65,161.44,66.65ZM149.52,211.37l-96.68-24c11.24-28.75,39.14-86.59,81.85-87C134.69,100.39,178.26,98.64,149.52,211.37Zm37.41-77.6L211.69,121S215.07,173.16,186.93,133.77Z'/%3E%3C/svg%3E");
    background-color: ${theme.COLOR_WHITE};
    background-size: 60%;
  `}
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  line-height: 1;
  margin-left: auto;
`;

export const PriceCrossings = styled.div`
  display: flex;
  flex-direction: row;
  text-align: right;
  line-height: 1;
  margin-left: auto;
`;

export const TotalPrice = styled.strong`
  ${({ strikeThrought }) => strikeThrought && css`
    text-decoration: line-through;
  `}
  font-size: 1rem;
  ${({ reviewItem }) => reviewItem && css`
    font-size: 1.25rem;
  `}
  ${({ borderColor }) => borderColor && css`
    color: ${borderColor};
  `}
`;

export const CancellingContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
`;

export const CancellingText = styled(Text)`
  padding: 0.35rem 1rem;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  background-color: ${({ theme }) => theme.COLOR_DANGER};
  color: ${({ theme }) => theme.COLOR_WHITE};
  text-transform: uppercase;
  font-size: 0.85rem;
`;

export const FooterContainer = styled.div`
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  border-radius: 0.2rem;
`;

export const PerNightPrice = styled.p`
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 0.2rem;
  text-align: right;
`;

export const Title = styled(TitleComponent)`
  font-size: 1rem;
`;

export const Address = styled(Text)``;

export const Detail = styled.div`
  display: flex;
`;

export const Label = styled(Text)`
  flex: 0 0 6rem;
`;

export const Value = styled(Text)`
  flex: 1;
`;

export const Actions = styled.footer`
  margin: 0.75rem 0;
  width: ${({ bigBasket }) => (bigBasket ? '50%' : '100%')};
`;

export const Button = styled(ButtonBordered)`
  width: 100%;
`;

export const WhiteButton = styled(ButtonBordered)`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  margin-right: 1rem;
  ${({ fullWidth = true }) => fullWidth && css`
    width: 100%;
    margin-right: 0;
  `}
`;

export const RedButton = styled(ButtonBordered)`
  width: 100%;
  max-width: 130px;

  ${({ theme }) => css`
  background-color: ${theme.COLOR_DANGER};
  border-color: ${theme.COLOR_DANGER};
  color: ${theme.COLOR_WHITE};

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: ${theme.COLOR_WHITE};
      color: ${theme.COLOR_DANGER};
    }
  }
  `}
`;

export const RemoveButton = styled((props) => (
  <SvgButton
    ariaLabel="Remove"
    size="small"
    {...props}
  >
    <Icon size="1.2rem" icon={binSvg} />
  </SvgButton>
))`
  background-color: ${({ bgColor, theme }) => bgColor || theme.COLOR_DANGER};
  color: ${({ theme }) => theme.COLOR_WHITE};

  &:disabled {
    background-color: ${({ theme }) => theme.COLOR_GRAY};
  }

  > span {
    ${({ small }) => small && css`
    padding: 0.25rem;
  `}
  }
`;

export const AcceptButton = styled((props) => (
  <SvgButton
    ariaLabel="Accept"
    size="small"
    {...props}
  >
    <Icon size="1.2rem" icon={checkSvg} />
  </SvgButton>
))`
  background-color: ${({ theme }) => theme.COLOR_SUCCESS};
  color: ${({ theme }) => theme.COLOR_WHITE};

  &:disabled {
    background-color: ${({ theme }) => theme.COLOR_GRAY};
  }
`;

export const ExtrasValueCol = styled(Col)`
  flex-flow: column wrap;
  flex: 0 0 5rem;
`;

export const MessageWrapper = styled.div`
  padding: 0.5rem 0;
  width: 100%;
`;

export const BookingLink = styled.a`
  text-decoration: underline;
`;
