export default function FormatAddress(address) {
  if (!address) return '';

  const {
    addressLines,
    county,
    country,
    postCode,
  } = address;

  const addressArray = [...addressLines, county, country, postCode];

  return `${addressArray.filter(o => !!o).join(', ')}`;
}

export const joinAddressLines = (address) => {
  const LIMIT = 40; // 40 characters per address line

  if (!address || !address?.addressLines?.length) return [];

  return address.addressLines.reduce(
    (acc, cur) => {
      if (!cur) return acc;
      if (!acc.length) {
        acc.push(cur);
        return acc;
      }
      const lastElement = acc[acc.length - 1];
      const shouldAppend = (lastElement.length + cur.length) <= LIMIT;
      if (shouldAppend) {
        acc[acc.length - 1] = `${lastElement}, ${cur}`;
      } else {
        acc.push(cur);
      }
      return acc;
    },
    [],
  );
};
